// declare global {
//   interface Object {
//     deepCopy<T>(obj: T): T;
//   }
// }

// function deepCopy<T>(obj: T): T {
//   let result = null;
//   if (!obj) return result;
//   if (obj instanceof Object && !(obj instanceof Function)) {
//     result = {};
//     for (const key in obj) {
//       if (key) {
//         result[key] = deepCopy(obj[key]);
//       }
//     }
//   }
//   return result || obj;
// }

// // Object.prototype.deepCopy = function <T>(item: T): T {
// //   // return null
// // };

export { };

