import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SentryHandler implements ErrorHandler {
  handleError(error) {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    }
    throw error;
  }
}
