<div ngbDropdown class="team">
  <button ngbDropdownToggle>
    <ng-container *ngIf="team">
      <img [src]="team.logo || '/assets/no-logo.png'">
      <span *ngIf="opened" class="opened-team">{{team.name}}</span>
    </ng-container>
    <ng-container *ngIf="!team">{{teamId}}</ng-container>
  </button>
  <div ngbDropdownMenu>
    <button ngbDropdownItem *ngFor="let team of teams" (click)="selectTeam(team.id,true)">
      {{team.name}}
    </button>
  </div>
</div>
<div *ngIf="system">
  <div class="d-flex align-items-center m-2">
    <div class="form-group mb-0">
      <input #customTeamId class="form-control bg-dark form-control-sm">
    </div>
    <button class="btn btn-outline-light btn-sm ml-2" (click)="selectTeam(customTeamId.value,true)">GO</button>
  </div>
</div>
<div class="menu">
  <ng-container *ngFor="let i of menu">
    <a *ngIf="i.route && (!i.disabled || system)" [routerLink]="i.route" [ngbTooltip]="(!opened) ? i.label : null"
      container="body" placement="right" class="item" routerLinkActive="active">
      <fa-icon [icon]="['fad',i.icon]" [fixedWidth]="true"></fa-icon>
      <span class="label">{{i.label}}
        <span *ngIf="i.badge === 'BIZ'" class="badge badge-biz">BIZ</span></span>
    </a>
    <a *ngIf="i.href" [href]="i.href" target="_blank" class="item">
      <fa-icon [icon]="['fad',i.icon]" [fixedWidth]="true"></fa-icon>
      <span class="label">{{i.label}}</span>
    </a>
    <div *ngIf="!i.label && (!i.disabled || system)" class="divider"></div>
  </ng-container>
</div>
<div class="divider"></div>
<div class="text-center">
  <button class="btn btn-sm btn-outline-secondary border-0" (click)="opened = !opened">
    <fa-icon [icon]="['fal','chevron-left']" *ngIf="opened" [fixedWidth]="true"></fa-icon>
    <fa-icon [icon]="['fal','chevron-right']" *ngIf="!opened" [fixedWidth]="true"></fa-icon>
    <span *ngIf="opened">collapse</span>
  </button>
</div>

<div class="footer" *ngIf="opened">
  <app-copyright></app-copyright>
  <app-dev-warning></app-dev-warning>
</div>
