import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilestackService } from '@filestack/angular';
import { TransformOptions } from 'filestack-js';
import { environment } from '../../../../environments/environment';
import { FilestackConfigService } from '../../../@core/services/filestack-config.service';
import { UploadService } from '../../../@core/services/upload.service';
import { FILE_UPLOAD_SOURCES } from '../../../@shared/models/file-upload-sources';

export interface SelectImage {
  width: string;
}

@Component({
  selector: 'app-select-image',
  templateUrl: './select-image.component.html',
  styleUrls: ['./select-image.component.scss'],
})
export class SelectImageComponent implements OnInit {

  @Output('imageChange') emitter: EventEmitter<string> = new EventEmitter<string>();
  @Input('image') set setMobileValue(value) {
    this.image = value;
  }

  @Input() config: any;

  image: string;
  temp: any;
  transformOptions: TransformOptions;
  loadingPicker: boolean;

  constructor(
    private uploadService: UploadService,
    private filestackService: FilestackService,
    private filestackConfigService: FilestackConfigService,
  ) { }

  ngOnInit() {
    this.transformOptions = {
      security: this.filestackConfigService.publicFsSecurity,
      cache: {
        expiry: 24 * 60 * 60,
      },
      resize: {
        width: 60,
        height: 60,
      },
    };

    this.filestackService.init(environment.config.filestackKey);
  }

  async changeImage() {

    const options = {
      ...this.config,
      maxFiles: 1,
      accept: ['image/*'],
      fromSources: FILE_UPLOAD_SOURCES,
      onClose: () => {
        this.loadingPicker = false;
      },
    };

    this.loadingPicker = true;
    const files = await this.uploadService.openPicker(options);

    if (files) {
      this.temp = files[0];
      this.image = this.temp.url;
      this.emitter.emit(this.image);
    }
  }

  clearImage() {
    this.temp = null;
    this.image = null;
    this.emitter.emit(null);
  }

}
