import { Component } from '@angular/core';
import { TeamService } from '../../../@core/services/team.service';

@Component({
  selector: 'app-billing-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss'],
})
export class ConnectComponent {

  get link() {
    return this.teamService.teamConnectUrl;
  }

  constructor(
    private teamService: TeamService,
  ) {
  }
}
