import { Component, HostBinding, OnInit } from '@angular/core';
import { LocalStorage } from '@efaps/ngx-store';
import { AuthService } from '../../../../@core/services/auth.service';
import { TeamService } from '../../../../@core/services/team.service';
import { CommonComponent } from '../../../components/common-component';
import { Team } from '../../../models/teams/team.model';
import { MENU } from './models/menu';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends CommonComponent implements OnInit {

  @LocalStorage('dashboard_sidebar_opened')
  @HostBinding('class.open')
  opened = true;

  teams: Team[];
  menu = MENU;

  get teamId() {
    return this.teamService.teamId;
  }

  get team() {
    return this.teamService.team;
  }

  get system() {
    return this.authService.me.settings['system'];
  }

  constructor(
    private teamService: TeamService,
    private authService: AuthService,
  ) {
    super();
  }

  ngOnInit() {
    this.teams = this.teamService.allTeams();
  }

  selectTeam(teamId: string, redirect: boolean) {
    this.teamService.teamId = teamId;
    if (redirect) {
      window.location.reload();
    }
  }
}
