import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pro-badge',
  templateUrl: './pro-badge.component.html',
  styleUrls: ['./pro-badge.component.scss'],
})
export class ProBadgeComponent{
  @Input() permission: string;
}
