import { Injectable } from '@angular/core';
import { AuthenticationConfiguration, FilestackConfiguration, MessageConfiguration, NotificationConfiguration, ProfileConfiguration, ProspectConfiguration, SessionConfiguration, TeamConfiguration } from '@echofin/libraries';
import * as Sentry from '@sentry/browser';
import * as debug from 'debug';
import { environment } from '../../environments/environment';

declare var window: any;

@Injectable({ providedIn: 'root' })
export class InitService {
  async init() {

    debug('app:init')('Initializing app');

    await fetch(`${environment.configUrl}?t=${Math.random()}`)
      .then(async response => {
        window.config = await response.json();
        Sentry.configureScope((scope) => {
          scope.addEventProcessor((event) => {
            return new Promise((resolve) => {
              event.environment = window.config.name;
              return resolve(event);
            });
          });
        });
      });
  }
}

export function init_app(initService: InitService) {
  return () => initService.init();
}
export function authenticationConfigurationFactory(): AuthenticationConfiguration {
  return { rootUrl: window.config.endpoints.authentication };
}
export function messageConfigurationFactory(): MessageConfiguration {
  return { rootUrl: window.config.endpoints.message };
}
export function notificationConfigurationFactory(): NotificationConfiguration {
  return { rootUrl: window.config.endpoints.notification };
}
export function profileConfigurationFactory(): ProfileConfiguration {
  return { rootUrl: window.config.endpoints.profile };
}
export function prospectConfigurationFactory(): ProspectConfiguration {
  return { rootUrl: window.config.endpoints.prospect };
}
export function sessionConfigurationFactory(): SessionConfiguration {
  return { rootUrl: window.config.endpoints.session };
}
export function teamConfigurationFactory(): TeamConfiguration {
  return { rootUrl: window.config.endpoints.team };
}
export function filestackConfigurationFactory(): FilestackConfiguration {
  return { rootUrl: window.config.endpoints.filestack };
}
