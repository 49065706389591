import { OnDestroy, Component } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-base',
  template: `
      <div>
          Common Component - not to be used as is. Only to be extended.
      </div>
  `})
export class CommonComponent implements OnDestroy {
  debug: boolean;
  subscriptions: Subscription = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  subscribe<T>(o: Observable<T>, cb: any) {
    // const s = o.pipe(skipWhile(x => !x)).subscribe(cb);
    const s = o.subscribe(cb);
    this.subscriptions.add(s);
  }
}
