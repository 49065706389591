import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-permissions-toggles',
  templateUrl: './permissions-toggles.component.html',
  styleUrls: ['./permissions-toggles.component.scss'],
})
export class PermissionsTogglesComponent {
  @Input() permission: 'ALLOW' | 'DENY' | 'INHERIT';
  @Input() inheritance: { hide: boolean, label: string, effect: 'ALLOW' | 'DENY' };
  @Input() enabled = true;
  @Input() rule: string;
  @Input() type: string;
  @Input() postMessage: any;

  @Output() toggled = new EventEmitter<string>();

  toggle(effect) {
    this.toggled.emit(effect);
  }

  get tooltipDescr() {
    switch (this.permission) {
      case 'INHERIT':
        if (this.inheritance) {
          const ad = this.inheritance.effect === 'DENY' ? 'Denied' : 'Allowed';
          return `The action is ${ad}. The permission is inherited by the ${this.inheritance.label} role`;
        }
        return 'The action is Denied';
      case 'ALLOW':
        return 'The action is Allowed';
      case 'DENY':
        return 'The action is Denied';
    }
  }

  get disabledReadDescr() {
    switch (this.type) {
      case 'team_everyone':
        return 'To deny read access to Everyone please make the Chatroom Private';
      case 'everyone':
        return 'To deny read access to the chatroom convert it to Private';
      case 'private':
        return 'Read access cannot be disabled for Private Members';
      case 'trial':
        return 'Read access cannot be disabled for Trial Members';
      case 'premium':
        return 'Read access cannot be disabled for Premium Members';
      default:
        return null;
    }
  }

  getInheritanceClass() {
    if (!this.enabled) return {};
    return {
      'rule-deny': this.permission === 'INHERIT' && this.inheritance.effect === 'DENY',
      'rule-allow': this.permission === 'INHERIT' && this.inheritance.effect === 'ALLOW',
    };
  }

  get isAllow() {
    if (!this.enabled && this.isSubPost) return this.subPostIsAllow;
    if (this.permission === 'ALLOW') return true;
    if (this.permission === 'INHERIT' &&
      this.inheritance &&
      this.inheritance.effect === 'ALLOW' &&
      this.inheritance.hide) return true;
    return false;
  }

  get isDeny() {
    if (!this.enabled && this.isSubPost) return this.subPostIsDeny;
    if (this.permission === 'DENY') return true;
    if (this.permission === 'INHERIT' &&
      this.inheritance &&
      this.inheritance.effect === 'DENY' &&
      this.inheritance.hide) return true;
    return false;
  }

  get isSubPost() {
    return (this.rule.startsWith('post_') && this.rule !== 'post_messages') || this.rule.startsWith('upload_');
  }

  get subPostIsAllow() {
    if (this.postMessage.permission === 'ALLOW') return true;
    if (this.postMessage.permission === 'INHERIT' &&
      this.postMessage.inheritance &&
      this.postMessage.inheritance.effect === 'ALLOW' &&
      this.postMessage.inheritance.hide) return true;
    return false;
  }

  get subPostIsDeny() {
    if (this.postMessage.permission === 'DENY') return true;
    if (this.postMessage.permission === 'INHERIT' &&
      this.postMessage.inheritance &&
      this.postMessage.inheritance.effect === 'DENY' &&
      this.postMessage.inheritance.hide) return true;
    return false;
  }

  get isRead() {
    return this.rule === 'read_messages' && this.disabledReadDescr != null;
  }
}
