import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FilestackService } from '@filestack/angular';
import { environment } from '../../../environments/environment';
import { FilestackConfigService } from '../services/filestack-config.service';

@Injectable({
  providedIn: 'root',
})
export class FilestackResolver implements Resolve<void> {

  constructor(
    private filestackService: FilestackService,
    private filestackConfigService: FilestackConfigService,
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.filestackService.init(environment.config.filestackKey);
    await this.filestackConfigService.setup();
  }
}
