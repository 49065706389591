import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../@core/services/auth.service';
import { TeamService } from '../../../../@core/services/team.service';

declare var window: any;

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ right: '-400px', opacity: 0 }),
        animate('200ms ease-in', style({ right: '0px', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ right: '-400px' })),
      ]),
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms 200ms ease-in', style({ opacity: 0.4 })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class TopbarComponent implements OnInit {
  chatUrl: string;

  displayWhatsNew = false;

  get me() {
    return this.authService.me;
  }

  get plan() {
    return this.teamService.teamAccount?.plan;
  }

  constructor(
    private teamService: TeamService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    const team = this.teamService.team;
    if (!team) return;
    this.chatUrl = `https://app.ensemble.chat/${team.name}`;
  }

  showWhatsNew() {
    this.displayWhatsNew = true;
  }

  closeWhatsNew() {
    this.displayWhatsNew = false;
  }
}
