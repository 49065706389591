<div class="header-logo">
  <img src="/assets/ensemble_white.svg">
</div>
<div class="header-menu">
  <div class="menu">
    <a *ngIf="plan" [routerLink]="['/account']">
      <span class="badge badge-{{plan.key}} mr-2">
        {{plan.name}} Plan</span>
    </a>
    <span id="topbar-username" class="mr-2">
      <img [src]="me.avatar || '/assets/no-avatar.png'" class="rounded-circle mr-2"
        style="width:32px;height:32px;margin-left: 5px;margin-right: 0px !important;">
      {{me.username}}
    </span>
    <span class="item" ngbTooltip="What's new" container="body" placement="bottom" (click)="showWhatsNew()">
      <fa-icon [icon]="['fas','bell']"></fa-icon>
    </span>
    <a [href]="chatUrl" target="_blank" class="item" ngbTooltip="Open team chatrooms" container="body"
      [placement]="['bottom','bottom-right']">
      <fa-icon [icon]="['fas','comments-alt']"></fa-icon>
    </a>
    <a [routerLink]="['/logout']" class="item" ngbTooltip="Logout" container="body"
      [placement]="['bottom','bottom-right']">
      <fa-icon [icon]="['fas','sign-out-alt']"></fa-icon>
    </a>
  </div>
</div>

<app-whatsnew *ngIf="displayWhatsNew" [@slideInOut] (closing)="closeWhatsNew()"></app-whatsnew>
<div *ngIf="displayWhatsNew" class="backdrop" [@fadeInOut] (click)="closeWhatsNew()"></div>
