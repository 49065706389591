<div class="single-box">
  <img src="assets/echofin_dark.png" class="img-fluid mb-4 p-2">
  <hr>
  <div class="text-center">
    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchDefault>

      </ng-container>
      <ng-container *ngSwitchCase="'error'">
        <div class="text-danger">
          <fa-icon [icon]="['fal','times-circle']" class="mb-2 d-block" size="4x"></fa-icon>
          <div><strong>{{error}}</strong></div>
          <div>{{errorDesc}}</div>
        </div>
        <hr>
        <div>
          <small class="text-muted"><a href="/">
              <fa-icon icon="arrow-left"></fa-icon> back to dashboard
            </a></small>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'already'">
        <div class="text-muted">
          <fa-icon [icon]="['fal','check-circle']" class="mb-2 d-block" size="4x"></fa-icon>
          <div>Already verified</div>
        </div>
        <hr>
        <div>
          <small class="text-muted"><a href="/">
              <fa-icon icon="arrow-left"></fa-icon> back to dashboard
            </a></small>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'complete'">
        <div class="text-success">
          <fa-icon [icon]="['fal','check-circle']" class="mb-2 d-block" size="4x"></fa-icon>
          <div>Your Stripe account is now Connected. Redirecting to Ensemble Dashboard...</div>
        </div>
        <hr>
        <div><small class="text-muted">If you are not automatically redirected to Dashboard, please <a href="/">click here</a>.</small>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <fa-icon [icon]="['fal','cog']" class="mb-2 d-block" size="4x" [spin]="true"></fa-icon>
        <div>verifying your account...</div>
      </ng-container>
    </ng-container>
  </div>
</div>
<div class="text-center text-muted p-2">
  <app-copyright></app-copyright>
</div>
