import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {

  apiDomains = [];

  constructor(
    public authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
  ) {
    this.apiDomains = Object
      .keys(environment.config.endpoints)
      .reduce((l, k) => {
        if (!l[environment.config.endpoints[k]]) {
          l.push(environment.config.endpoints[k]);
        }
        return l;
      },      []);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    const r = request.clone({
      withCredentials: this.apiDomains.contains(this.parseUrl(request.url).origin)
    });

    return next.handle(r).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !r.headers['X-StatusCheck']) {
          this.router.navigate(['/logout']);
        }
        return throwError(error);
      }));
  }

  parseUrl(url) {
    const m = url.match(/^(([^:\/?#]+:)?(?:\/\/((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/);
    const r = {
      hash: m[10] || '',                   // #asd
      host: m[3] || '',                    // localhost:257
      hostname: m[6] || '',                // localhost
      href: m[0] || '',                    // http://username:password@localhost:257/deploy/?asd=asd#asd
      origin: m[1] || '',                  // http://username:password@localhost:257
      pathname: m[8] || (m[1] ? '/' : ''), // /deploy/
      port: m[7] || '',                    // 257
      protocol: m[2] || '',                // http:
      search: m[9] || '',                  // ?asd=asd
      username: m[4] || '',                // username
      password: m[5] || ''                 // password
    };
    if (r.protocol.length == 2) {
      r.protocol = 'file:///' + r.protocol.toUpperCase();
      r.origin = r.protocol + '//' + r.host;
    }
    r.href = r.origin + r.pathname + r.search + r.hash;
    return r;
  }
}
