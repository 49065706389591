import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DynamicConfigService {

  baseUrl: string;

  constructor(
    private http: HttpClient
  ) {

    var url = [environment.dynamicConfigBaseUrl];
    if (environment.config.name !== "ensemble") {
      url.push(environment.config.name);
    }

    this.baseUrl = url.join("/");
  }

  getFeatures() {
    return this.http
      .get<{ features: any; }>(`https://assets.echofin.com/config/ensemble/features.json?t=${Math.random()}`)
      .toPromise()
      .then(resp => resp.features, (res) => {
        console.log('Could not load features');
      });
  }

  getPlans() {
    return this.http
      .get<{ plans: any; }>(`https://assets.echofin.com/config/ensemble/plans.json?t=${Math.random()}`)
      .toPromise()
      .then(resp => resp.plans, (res) => {
        console.log('Could not load plans');
      });
  }

}
