import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../@core/services/auth.service';
import { TeamService } from '../../../@core/services/team.service';
import { UpdateService } from '.././../../@core/services/update.service';

@Component({
  selector: 'app-layout-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {

  process;
  appLoaded = false;
  routeLoading = false;
  updateSubscription: Subscription;

  get isPaymentPastDue() {
    return this.teamService.teamAccount?.plan?.status === 'past_due'
  }

  constructor(
    private authService: AuthService,
    private teamService: TeamService,
    private router: Router,
    private toastr: ToastrService,
    private updateService: UpdateService,
    private angulartics2Amplitude: Angulartics2Amplitude,
  ) { }

  ngOnInit() {

    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    if (environment.config.name === 'echofin') {
      this.angulartics2Amplitude.setUsername(this.authService.me.username);

      window['_loq'].push(['tag', this.authService.me.username]);
      window['_loq'].push(['tag', this.teamService.team?.name]);
    }

    Sentry.setUser({
      id: this.authService.me.id,
      email: this.authService.me.email,
      username: this.authService.me.username,
    });

    Sentry.setTags({
      'team.id': this.teamService.teamId,
      'team.name': this.teamService.team?.name,
    });

    this.appLoaded = true;

    this.updateService.setup();
  }

  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.routeLoading = true;
    }
    if (event instanceof NavigationEnd) {
      this.routeLoading = false;
    }
    if (event instanceof NavigationCancel) {
      this.routeLoading = false;
    }
    if (event instanceof NavigationError) {
      this.routeLoading = false;
      this.toastr.error('Could not load module. Check your internet connection', 'Load Failed');
    }
  }
}
