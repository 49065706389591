import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-explainer',
  templateUrl: './explainer.component.html',
  styleUrls: ['./explainer.component.scss'],
})
export class ExplainerComponent{
  @Input() class: string;
}
