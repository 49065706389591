import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule as FontAwesomeModuleVentor } from '@fortawesome/angular-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

@NgModule({
  imports: [
    FontAwesomeModuleVentor,
  ],
  exports: [
    FontAwesomeModuleVentor,
  ],
})
export class CustomFontawesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fad, fas, far, fal, fab);
  }
}
