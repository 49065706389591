import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(public toastrService: ToastrService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request);
      // .pipe(
      //   retry(1),
      //   catchError((error: HttpErrorResponse) => {
      //     let errorMessage = '';
      //     if (error.error instanceof ErrorEvent) {
      //       // client-side error
      //       errorMessage = `Error: ${error.error.message}`;
      //       this.toastrService.error(errorMessage);
      //     } else if (error.status === 0) {
      //       this.toastrService.error('Could not connect to server');
      //     } else {
      //       this.toastrService.error(`Error Code: ${error.status}\nMessage: ${error.message}`);
      //     }
      //     // window.alert(errorMessage);
      //     return throwError(errorMessage);
      //   })
      // );
  }
}
