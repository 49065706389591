import { Component } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor() {
    if (environment.config.name === 'ensemble') {
      // window['__lo']();

      const amplitudeId = '85e5fcc08bf872fb202cb83199f5701f';
      window['amplitude'].getInstance().init(amplitudeId);
    }
  }
}
