import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FaIconModel } from '../../../@core/services/team.service';

@Component({
  selector: 'app-icon-list',
  templateUrl: './icon-list.component.html',
  styleUrls: ['./icon-list.component.scss'],
})
export class IconListComponent implements OnInit {

  @Input() search: boolean = false;
  @Input() icons: any[] = null;
  @Input() selected: any;
  @Output() onSelect = new EventEmitter();

  filteredIcons: any[];

  constructor(
  ) { }

  ngOnInit() {
    this.load();
  }

  load(term: string = null) {
    if (!term) this.filteredIcons = this.icons;
    this.filteredIcons = Object.keys(this.icons)
      .reduce((l, i) => {
        const icon: FaIconModel = this.icons[i];
        if (!term || (icon.search.terms.indexOf(term.toLowerCase()) !== -1 && l.length < 10)) {
          l.push(icon);
        }
        return l;
      },      []);
  }

  select(icon) {
    this.onSelect.emit(icon.svg[icon.free[0]]);
  }
}
