<button class="btn btn-debug" (click)="showDebug = !showDebug" ngbTooltip="debug" container="body"
  [ngClass]="{active:showDebug}" position="left" *ngIf="debug">
  <fa-icon [icon]="['fal','code']" [fixedWidth]="true"></fa-icon>
</button>

<div class="card">
  <div class="table-responsive">
    <ng-container *ngIf="data; else loading">
      <ng-container *ngIf="data.length; else empty">
        <ng-container *ngIf="!showDebug">
          <div class="w-100 mb-4 d-flex justify-content-between" *ngIf="data.length > limit">
            <div class="d-flex align-items-center">
              <span class="mr-2">Show</span>
              <select class="mr-2 form-control form-control-sm" (change)="resetLimit($event.target.value)">
                <option value="10" selected>10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
              <span class="mr-2">of</span>
              <strong class="mr-2">{{data.length}}</strong>
            </div>
            <ul class="pagination pagination-sm m-0">
              <li class="page-item"><button class="page-link" (click)="previous()">Previous</button></li>
              <ng-container *ngFor="let p of pages">
                <li class="page-item" [ngClass]="{active:page === p}"><button class="page-link"
                    (click)="switch(p)">{{p+1}}</button></li>
              </ng-container>
              <li class="page-item"><button class="page-link" (click)="next()">Next</button></li>
            </ul>
          </div>
          <table class="table">
            <thead class="thead-light">
              <tr>
                <ng-container *ngFor="let col of config.cols;let last=last">
                  <ng-container *ngIf="!last || (last && !config.refresh)">
                    <th [ngStyle]='col.style || {}'>{{col.label}}</th>
                  </ng-container>
                </ng-container>
                <th *ngIf="config.refresh">
                  <button class="btn btn-outline-secondary border-0 py-0" (click)="config.refresh()">
                    <fa-icon [icon]="['fal','sync']"></fa-icon>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data | paginate:page:limit" [id]="item[config.primary]">
                <ng-container *ngFor="let col of config.cols; let i = index">
                  <ng-container [ngSwitch]="col.type">
                    <td *ngSwitchCase="'custom'" [ngStyle]='col.style || {}'>
                      <ng-container *ngIf="col.show && col.show(item)">
                        <ng-container *ngTemplateOutlet=" templates[col.template];context:item"></ng-container>
                      </ng-container>
                    </td>
                    <td *ngSwitchCase="'badge'" [ngStyle]='col.style || {}'>
                      <ng-container *ngFor="let badgeItem of col.badgeItems">
                        <div *ngIf="col.data(item) === badgeItem.value" class="badge badge-{{badgeItem.class}} w-100"
                          (click)="col.onClick(item)" [ngStyle]='badgeItem.style || {}'>
                          {{badgeItem.label}}
                          <fa-icon *ngIf="col.icon && col.onClick" [icon]="col.icon">
                          </fa-icon>
                        </div>
                      </ng-container>
                    </td>
                    <td *ngSwitchCase="'badge_icon'" [ngStyle]='col.style || {}'>
                      <ng-container *ngFor="let badgeItem of col.badgeItems">
                        <div *ngIf="col.data(item) === badgeItem.value" class="badge badge-{{badgeItem.class}} w-100"
                          (click)="col.onClick(item)" [ngStyle]='badgeItem.style || {}'>
                          <fa-icon *ngIf="badgeItem.icon" [icon]="badgeItem.icon" style="padding-right: 2px;">
                          </fa-icon>
                          {{badgeItem.label}}
                        </div>
                      </ng-container>
                    </td>
                    <td *ngSwitchCase="'button'" [ngStyle]='col.style || {}'>
                      <button *ngIf="col.show && col.show(item) || !col.show" class="btn-table-action  btn-table-action-lg"
                        (click)="col.onClick(item)" [title]="col.title || ''" [ngbTooltip]="col.tooltip"
                        container="body">
                        <fa-icon [icon]="col.icon"></fa-icon>
                        <span *ngIf="col.icon=='fal,cog'">Manage</span>
                      </button>
                    </td>
                    <td *ngSwitchCase="'date'" [ngStyle]='col.style || {}'>
                      {{col.data(item) | date:'short'}}
                    </td>
                    <td *ngSwitchCase="'datetime'" [ngStyle]='col.style || {}'>
                      {{col.data(item) | date:'shortDate'}} {{col.data(item) | date:'HH:mm:ss'}}
                    </td>
                    <td *ngSwitchCase="'image'" style="width:56px" [ngStyle]='col.style || {}'>
                      <img [src]="col.data(item) || col.empty" class="rounded" style="width:px;height:32px"
                        [alt]="col.label">
                    </td>
                    <td *ngSwitchCase="'json'" [ngStyle]='col.style || {}'>
                      <pre>{{col.data(item) || '-'}}</pre>
                    </td>
                    <td *ngSwitchDefault [ngStyle]='col.style || {}'>
                      {{col.data(item) || '-'}}
                    </td>
                  </ng-container>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </ng-container>
        <ng-container *ngIf="showDebug">
          <div class="card card-debug mb-2">
            <h4 class="text-white">data</h4>
            <pre>{{data|json}}</pre>
          </div>
          <div class="card card-debug mb-2">
            <h4 class="text-white">config</h4>
            <pre>{{config|json}}</pre>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <div class="p-5 text-center text-muted">
    <fa-icon [icon]="['fal','sync-alt']" [spin]="true" size="2x"></fa-icon>
  </div>
</ng-template>

<ng-template #empty>
  <div class="p-5 text-center text-muted">
    <h2 style="font-weight:300"></h2>
  </div>
</ng-template>