import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ent-badge',
  templateUrl: './ent-badge.component.html',
  styleUrls: ['./ent-badge.component.scss'],
})
export class EntBadgeComponent{
  @Input() permission: string;
}
