import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WebStorageModule } from '@efaps/ngx-store';
import { FilestackModule } from '@filestack/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgPipesModule } from 'ngx-pipes';
import { ConnectComponent } from './components/connect/connect.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { DevWarningComponent } from './components/dev-warning/dev-warning.component';
import { EntBadgeComponent } from './components/ent-badge/ent-badge.component';
import { ExplainerComponent } from './components/explainer/explainer.component';
import { IconListComponent } from './components/icon-list/icon-list.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { PermissionsTogglesComponent } from './components/permissions-toggles/permissions-toggles.component';
import { ProBadgeComponent } from './components/pro-badge/pro-badge.component';
import { SelectImageComponent } from './components/select-image/select-image.component';
import { TableComponent } from './components/table/table.component';
import { UnderConstructionComponent } from './components/under-construction/under-construction.component';
import { ClickStopPropagation } from './directives/click-stop-propagation.directive';
import { MainComponent } from './layouts/main/main.component';
import { SidebarComponent } from './layouts/main/sidebar/sidebar.component';
import { TopbarComponent } from './layouts/main/topbar/topbar.component';
import { UpgradeComponent } from './layouts/main/upgrade/upgrade.component';
import { WhatsnewComponent } from './layouts/main/whatsnew/whatsnew.component';
import { CustomFontawesomeModule } from './modules/fontawesome.module';
import { FormatDistancePipe } from './pipes/format-distance';
import { HtmlSafePipe } from './pipes/html-safe.pipe';
import { PaginatePipe } from './pipes/paginate.pipe';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    CustomFontawesomeModule,
    WebStorageModule,
    NgPipesModule,
    RouterModule.forChild([]),
    FilestackModule,
  ],
  declarations: [
    UnderConstructionComponent,
    PermissionsTogglesComponent,
    ProBadgeComponent,
    EntBadgeComponent,
    TableComponent,
    NoDataComponent,
    CopyrightComponent,
    DevWarningComponent,
    IconListComponent,
    SelectImageComponent,
    ConnectComponent,
    ExplainerComponent,
    LoaderComponent,

    MainComponent,
    SidebarComponent,
    TopbarComponent,
    UpgradeComponent,
    WhatsnewComponent,

    SafePipe,
    HtmlSafePipe,
    PaginatePipe,
    FormatDistancePipe,

    ClickStopPropagation,
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgbModule,
    CustomFontawesomeModule,
    NgPipesModule,
    FilestackModule,

    CopyrightComponent,
    UnderConstructionComponent,
    PermissionsTogglesComponent,
    ProBadgeComponent,
    EntBadgeComponent,
    TableComponent,
    NoDataComponent,
    DevWarningComponent,
    IconListComponent,
    SelectImageComponent,
    ConnectComponent,
    LoaderComponent,

    MainComponent,

    SafePipe,
    PaginatePipe,
    HtmlSafePipe,
    FormatDistancePipe,

    ClickStopPropagation,
    ExplainerComponent,
  ],
})
export class SharedModule { }
