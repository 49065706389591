import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AuthenticationApiModule, AuthenticationConfiguration, FilestackApiModule, FilestackConfiguration, MessageApiModule, MessageConfiguration, NotificationApiModule, NotificationConfiguration, ProfileApiModule, ProfileConfiguration, ProspectApiModule, ProspectConfiguration, SessionApiModule, SessionConfiguration, TeamApiModule, TeamConfiguration } from '@echofin/libraries';
import { FilestackModule } from '@filestack/angular';
import { Angulartics2Module } from 'angulartics2';
import { NgxStripeModule } from 'ngx-stripe';
import { SentryHandler } from './handlers/sentry.handler';
import { authenticationConfigurationFactory, filestackConfigurationFactory, InitService, init_app, messageConfigurationFactory, notificationConfigurationFactory, profileConfigurationFactory, prospectConfigurationFactory, sessionConfigurationFactory, teamConfigurationFactory } from './init-functions';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AuthService } from './services/auth.service';
import { DynamicConfigService } from './services/dynamic-config.service';
import { TeamService } from './services/team.service';
import { TrackingService } from './services/tracking.service';
import { UpdateService } from './services/update.service';
import { UploadService } from './services/upload.service';

@NgModule({
  imports: [
    AuthenticationApiModule,
    MessageApiModule,
    NotificationApiModule,
    ProfileApiModule,
    ProspectApiModule,
    SessionApiModule,
    TeamApiModule,
    FilestackApiModule,
    FilestackModule.forRoot({}),
    Angulartics2Module.forRoot(),
    NgxStripeModule.forRoot(),
  ],
  providers: [
    TeamService,
    UploadService,
    UpdateService,
    AuthService,
    TrackingService,
    DynamicConfigService,
    { provide: ErrorHandler, useClass: SentryHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    InitService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [InitService],
      multi: true,
    },
    { provide: AuthenticationConfiguration, useFactory: authenticationConfigurationFactory, deps: [InitService] },
    { provide: MessageConfiguration, useFactory: messageConfigurationFactory, deps: [InitService] },
    { provide: NotificationConfiguration, useFactory: notificationConfigurationFactory, deps: [InitService] },
    { provide: ProfileConfiguration, useFactory: profileConfigurationFactory, deps: [InitService] },
    { provide: ProspectConfiguration, useFactory: prospectConfigurationFactory, deps: [InitService] },
    { provide: SessionConfiguration, useFactory: sessionConfigurationFactory, deps: [InitService] },
    { provide: TeamConfiguration, useFactory: teamConfigurationFactory, deps: [InitService] },
    { provide: FilestackConfiguration, useFactory: filestackConfigurationFactory, deps: [InitService] },
  ],
})
export class CoreModule { }
