import { Component } from '@angular/core';

@Component({
  selector: 'app-no-data',
  template: `
    <div class="text-center p-4 text-muted">
        <div style="font-size:1rem;">No results found</div>
    </div>
  `,
})
export class NoDataComponent {
}
