import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TeamFeaturesServiceApi, TeamMembersServiceApi, TeamTeamsServiceApi } from '@echofin/libraries';
import { LocalStorage } from '@efaps/ngx-store';
import * as debug from 'debug';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Team } from '../../@shared/models/teams/team.model';

export interface FaIconCategoryModel {
  [key: string]: {
    icons: string[],
    label: string,
  };
}

export interface FaIconModel {
  changes: string[];
  ligatures: string[];
  search: {
    terms: string[],
  };
  styles: string[];
  unicode: string;
  label: string;
  voted: boolean;
  svg: {
    brands: {
      last_modified: number,
      raw: string,
      viewBox: number[],
      width: number,
      height: number,
      path: string,
    },
  };
  free: string[];
}

@Injectable({
  providedIn: 'root',
})
export class TeamService {

  @LocalStorage('dashboard_teamId') teamId: string;

  rolesUpdated$ = new Subject();

  iconCategories: FaIconCategoryModel;

  allIcons: { [key: string]: FaIconModel };

  teams: Team[] = [];
  team: Team = null;
  teamFeatures: string[];
  teamAccount: any;
  teamConnectUrl: string;
  cards: any[];
  hasConnAccount: boolean;


  constructor(
    private teamMembersApi: TeamMembersServiceApi,
    private teamApi: TeamTeamsServiceApi,
    private featuresApi: TeamFeaturesServiceApi,
    private http: HttpClient,
  ) {
    this.loadAssets();
  }

  async loadTeam(teamId: string) {
    debug('app:service:team')('select team %s', teamId);
    this.teamId = teamId;
    this.team = await this.teamApi.GetTeam({ teamId: this.teamId, ext: true }).toPromise();
    debug('app:service:team')('load options');
    await this.loadFeatures();
    await this.loadAccount();
  }

  async loadTeams() {
    this.teams = await this.teamApi.GetUserTeams(true).toPromise();
    this.team = (this.teams || []).find(t => t.id === this.teamId);
  }

  allTeams() {
    return this.teams;
  }

  checkFeature(name: string) {
    return this.teamFeatures?.indexOf(name) > -1;
  }

  async loadFeatures() {
    await this.featuresApi
      .GetFeatures(this.teamId)
      .toPromise()
      .then((v: any) => {
        this.teamFeatures = v?.features || []
      });
  }

  searchMembers(username$: Observable<string>) {
    return username$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((username) => {
        if (username.length < 2) {
          return of([]);
        }
        return this.teamMembersApi.SearchMembers({
          TeamId: this.teamId,
          Username: username,
        });
      }),
    );
  }

  async loadAccount() {
    await this.http
      .get<any>(`${environment.config.endpoints.billing}/billing/accounts`, {
        params: {
          teamId: this.teamId,
        },
      })
      .toPromise()
      .then((resp) => {
        this.teamAccount = resp.account
        this.teamConnectUrl = resp.connect
        this.cards = resp.cards
        this.hasConnAccount = !!resp.account.stripe && !!resp.account.stripe.stripeId;
      });
  }

  private loadAssets() {
    this.http
      .get<FaIconCategoryModel>('https://assets.echofin.com/icons/categories.json')
      .toPromise()
      .then((data) => {
        this.iconCategories = data;
      });

    this.http
      .get<{ [key: string]: FaIconModel; }>('https://assets.echofin.com/icons/icons.json')
      .toPromise()
      .then((data) => {
        this.allIcons = data;
      });
  }
}
