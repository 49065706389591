import { MainGuard } from './@core/guards/main.guard';
import { FilestackResolver } from './@core/resolvers/filestack.resolver';
import { TeamResolver } from './@core/resolvers/team.resolver';
import { MainComponent } from './@shared/layouts/main/main.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { NoTeamComponent } from './no-team/no-team.component';
import { RedirectComponent } from './redirect/redirect.component';
import { StripeComponent } from './stripe/stripe.component';

export const APP_ROUTES = [
  {
    path: '',
    component: MainComponent,
    canActivate: [
      MainGuard,
    ],
    resolve: {
      team: TeamResolver,
      filestack: FilestackResolver,
    },
    children: [
      { path: 'overview', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      // tslint:disable-next-line: max-line-length
      { path: 'console', loadChildren: () => import('./events-console/events-console.module').then(m => m.EventsConsoleModule) },
      // tslint:disable-next-line: max-line-length
      { path: 'permissions', loadChildren: () => import('./permissions/permissions.module').then(m => m.PermissionsModule) },
      { path: 'rooms', loadChildren: () => import('./rooms/rooms.module').then(m => m.RoomsModule) },
      { path: 'billing', loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule) },
      { path: 'frames', loadChildren: () => import('./frames/frames.module').then(m => m.FramesModule) },
      { path: 'sidebar', loadChildren: () => import('./sidebar/sidebar.module').then(m => m.SidebarModule) },
      { path: 'users', loadChildren: () => import('./members/members.module').then(m => m.MembersModule) },
      { path: 'branding', loadChildren: () => import('./branding/branding.module').then(m => m.BrandingModule) },
      { path: 'widgets', loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule) },
      { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
      { path: 'webhooks', loadChildren: () => import('./webhooks/webhooks.module').then(m => m.WebhooksModule) },
      // tslint:disable-next-line: max-line-length
      { path: 'invitations', loadChildren: () => import('./invitations/invitations.module').then(m => m.InvitationsModule) },
      { path: '', pathMatch: 'full', redirectTo: 'overview' },
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'no-team', component: NoTeamComponent },
  { path: 'stripe', component: StripeComponent },
  { path: 'rd', component: RedirectComponent },
];
