export const MENU: Partial<Menu>[] = [
  { route: '/overview', label: 'Overview', icon: 'chart-line' },
  { divider: true },
  { route: '/users', label: 'Users', icon: 'users' },
  { route: '/invitations', label: 'Invitations', icon: 'envelope-open-text' },
  { route: '/permissions', label: 'Roles & Permissions', icon: 'shield-alt' },
  // { route: '/payments', label: 'Payments', icon: 'check-square'},
  // { route: '/subscriptions', label: 'Subscriptions', icon: 'money-bill-alt'},
  // { route: '/payouts', label: 'Payouts', icon: 'university'},
  { divider: true },
  // { route: '/plans', label: 'Billing Plans', icon: 'credit-card'},
  // { route: '/coupons', label: 'Coupons', icon: 'tags'},
  // { divider: true},
  { route: '/rooms', label: 'Chatrooms', icon: 'comments' },
  { route: '/frames', label: 'Services', icon: 'copy' },
  { route: '/sidebar', label: 'Sidebar', icon: 'list-ul' },
  { route: '/widgets', label: 'Widgets', icon: 'box-full' },
  { route: '/branding', label: 'Branding', icon: 'drafting-compass' },
  { divider: true },
  { route: '/billing/plans', label: 'Billing Plans', icon: 'credit-card' },
  // { route: '/billing/coupons', label: 'Coupons', icon: 'list-ul' },
  { route: '/billing/subscriptions', label: 'Subscriptions', icon: 'file-invoice-dollar' },
  { route: '/billing/payments', label: 'Payments', icon: 'sack-dollar' },
  { divider: true },
  { route: '/webhooks', label: 'Webhooks', icon: 'chart-network' },
  // { route: '/activity', label: 'Online Activity', icon: 'podcast'},
  // { route: '/compliance', label: 'Compliance', icon: 'balance-scale'},
  // { divider: true},
  // { route: '/api', label: 'API', icon: 'code'},
  // { route: '/events-console', label: 'Console', icon: 'terminal'},
  // { divider: true},
  { divider: true },
  { route: '/account', label: 'Account', icon: 'cog' },
];

export interface Menu {
  route: string;
  href: string;
  label: string;
  icon: string;
  disabled: boolean;
  divider: boolean;
  badge?: 'BIZ';
}
