import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamService } from '../@core/services/team.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private teamService: TeamService,
  ) { }

  ngOnInit() {
    setTimeout(async () => {
      await this.teamService.loadTeam(this.route.snapshot.queryParams['tid']);
      this.router.navigateByUrl('/');
    },         3000);
  }

}
