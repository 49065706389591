import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser';
import * as debug from 'debug';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import './extensions';

debug.enable('app:*');

if (environment.production) {
  const sentryConfig = {
    dsn: environment.sentryDsn,
    release: environment.build.hash,
  };
  debug('app:main')('initiate sentry with %O', sentryConfig);
  Sentry.init(sentryConfig);

  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
