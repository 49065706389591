<app-explainer>
  To enable Premium Chatrooms you will need to connect your Stripe account to Ensemble. If you already own a Stripe
  account the procedure is a single step, otherwise, you will need to create a Stripe account first. After connecting
  your Stripe account you will be able to create custom billing plans and attach them to Premium Chatrooms.
  <br><i>
    <b>Notice:</b>
    Ensemble transaction fees apply according to your subscription plan in addition to the standard Stripe processing
    fees.
  </i>
</app-explainer>

<div class="card mx-auto" style="max-width: 400px;">
  <div class="card-body text-center">
    <h2>Stripe Connect</h2>
    <div>Please connect your Stripe account to enable Premium Chatrooms</div>
    <hr>
    <a id="stripe_button" [href]="link | safe" class="connect-button">
      <span>Connect with Stripe</span>
    </a>
  </div>
</div>
