import { Build } from '../app/@shared/models/build';
import { Config } from '../app/@shared/models/config';
import * as build from '../assets/build.json';

declare var window: any;

class Environment {
  production = false;
  build: Build = build['default'];
  sentryDsn = 'https://aa803bb54efd4124b98b80a6010a9e6a@sentry.echofin.app//3';

  configUrl = 'config.json';
  dynamicConfigBaseUrl = 'https://assets.echofin.com/config';

  get config(): Config {
    return window.config || {};
  }
}

export const environment = new Environment();
