import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { TeamService } from '../services/team.service';

@Injectable({
  providedIn: 'root',
})
export class MainGuard implements CanActivate {

  constructor(
    private router: Router,
    private teamService: TeamService,
    private authService: AuthService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.authService.loadMe();
    if (!this.authService.me) {
      return this.router.parseUrl('/logout');
    }

    await this.teamService.loadTeams();

    if (this.authService.me.settings['system']) {
      return true;
    }

    if (!this.teamService.teams.length) {
      return this.router.parseUrl('/no-team');
    }

    return true;
  }
}
