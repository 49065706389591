import { Injectable } from '@angular/core';
import { FilestackPolicyServiceApi } from '@echofin/libraries';

@Injectable({
  providedIn: 'root',
})
export class FilestackConfigService {

  publicFsSecurity: {
    policy: string;
    signature?: string;
  };

  constructor(
    private fsApi: FilestackPolicyServiceApi,
  ) { }

  async setup() {
    this.publicFsSecurity = await this.fsApi
      .GetDefaultPolicy()
      .toPromise()
      .then((resp) => {
        return resp as {
          policy: string;
          signature?: string;
        };
      })
      .catch(() => {
        return { policy: null, signature: null };
      });
  }

  getPickerSecurity() {
    return this.fsApi
      .GetPickerPolicy()
      .toPromise()
      .then((resp) => {
        return resp as {
          policy: string;
          signature: string;
        };
      })
      .catch(() => {
        return { policy: null, signature: null };
      });
  }
}
