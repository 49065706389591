import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-under-construction',
  template: `
    <div class="text-center p-4 text-muted">
        <fa-icon [icon]="['fal',icon]" style="font-size:4rem"></fa-icon>
        <h2 class="display-4">{{title}}</h2>
    </div>
  `,
})
export class UnderConstructionComponent {
  @Input() title;
  @Input() icon;
}
