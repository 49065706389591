<ng-container *ngIf="appLoaded; else appLoader">
  <app-topbar></app-topbar>
  <div class="main-wrapper">
    <app-sidebar></app-sidebar>
    <div class="page-wrapper" [ngClass]={loading:routeLoading}>
      <div *ngIf="isPaymentPastDue" class="alert alert-danger m-4 d-flex align-items-center justify-content-between"
        style="max-width:860px">
        <div>
          <div><strong>Your team's billing plan payment is past due.</strong></div>
          <div>Please update your billing details and pay any outstanding invoices..</div>
        </div>
        <button class="btn btn-outline-danger" [routerLink]="['/account']">Account</button>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>

<ng-template #appLoader>
  <div class="root-loader">
    <div class="loader">
      <img src="/assets/echofin_dark.png">
      <span class="spinner"></span>
    </div>
  </div>
  <span class="process-message">{{process || 'loading...'}}</span>
</ng-template>
