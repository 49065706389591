<div class="header">
    <h5 class="title">What's new in Ensemble</h5>
    <button class="btn btn-close" (click)="close()">
        <fa-icon [icon]="['fal','times']"></fa-icon>
    </button>
</div>
<div class="items-container">
    <div *ngIf="loading" class="loader">
        <fa-icon *ngIf="loading" [icon]="['fad','spinner-third']" [fixedWidth]="true" size="5x" [spin]="true"></fa-icon>
    </div>
    <ng-container *ngIf="!loading">
        <div *ngFor="let item of items; trackByFn" class="item">
            <div class="tags">
                <span *ngFor="let tag of item.tags" class="tag">
                    <i class="dot" [style]="'background-color: '+getTagColor(tag)+';'"></i>{{tag}}
                </span>
            </div>

            <h6>{{item.title}}</h6>
            <p [innerHTML]="item.desc"></p>
            <div *ngIf="item.img" class="img-wrapper"><img [src]="item.img"></div>
            <span class="time">
                <fa-icon [icon]="['fal','calendar']"></fa-icon> {{item.date | date:'mediumDate'}}
            </span>
            <div class="feedback">
                <div *ngIf="sentFeedbackTitle.indexOf(item.title)<0" class="emoji-container">
                    <span class="react-emoji" title="I like this" (click)="trackEvent(item.title, 'happy')">😀
                    </span>
                    <span class="react-emoji" title="It's ok" (click)="trackEvent(item.title, 'neutral')">😐
                    </span>
                    <span class="react-emoji" title="I don't like this" (click)="trackEvent(item.title, 'unhappy')">😩
                    </span>
                </div>
                <span *ngIf="sentFeedbackTitle.indexOf(item.title)>=0" class="p-2">Thank you for your
                    feedback!</span>
            </div>
        </div>
    </ng-container>
</div>