<input *ngIf="search" #iconTerm class="form-control form-control-sm" placeholder="Filter icons..."
  (keyup)="load(iconTerm.value)">

<div class="icons d-flex flex-wrap">
  <ng-container *ngIf="filteredIcons; else loading">
    <ng-container *ngIf="filteredIcons.length; else empty">
      <ng-container *ngFor="let icon of filteredIcons">
        <button (click)="select(icon)" [ngClass]="{active:selected === icon.svg[icon.free[0]].raw}"
          class="btn btn-outline-primary">
          <span class="svg" [innerHtml]="icon.svg[icon.free[0]].raw | htmlsafe"></span>
        </button>
      </ng-container>

    </ng-container>
  </ng-container>
</div>

<ng-template #empty>
  <div class="text-center w-100 p-4">nothing was found</div>
</ng-template>

<ng-template #loading>
  <div class="text-center w-100 p-4">loading...</div>
</ng-template>
