import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from './@core/core.module';
import { SharedModule } from './@shared/shared.module';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { NoTeamComponent } from './no-team/no-team.component';
import { RedirectComponent } from './redirect/redirect.component';
import { StripeComponent } from './stripe/stripe.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    RouterModule.forRoot(APP_ROUTES, {
      preloadingStrategy: PreloadAllModules,
      // enableTracing: true//!environment.production
    }),

    CoreModule,
    SharedModule,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    NoTeamComponent,
    StripeComponent,
    RedirectComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
