<span class="badge badge-pro">PRO</span>
<!-- <span [ngbPopover]="permPopoverContent" container="body" [autoClose]="true" placement="bottom"
  popoverTitle="Pro Feature" class="badge badge-primary">PRO</span>

<ng-template #permPopoverContent>
  <div style="width:200px;">
    <ng-container [ngSwitch]="permission">
      <div *ngSwitchCase="'FINE_GRAINED_PERMS'">
        You need the <b>Enterprise</b> plan for manage these permissions
      </div>
      <div *ngSwitchDefault>
        Premium plan is required
      </div>
    </ng-container>
    <a [routerLink]="['/premium']" class="btn btn-sm btn-dark btn-block mt-2">Upgrade</a>
  </div>
</ng-template> -->
