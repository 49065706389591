import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingService } from '../@core/services/billing.service';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
})
export class StripeComponent implements OnInit {

  ERRORS = {
    no_code: 'Wrong url. Please verify that you redirect directly from stripe or try again.',
    invalid_grant: 'Stripe connection was failed',
    access_denied: 'Stripe connection was canceled',
    stripe_account_in_use: 'Stripe account is already in use from another team',
  };

  loading: boolean;

  status;
  error: string;
  errorDesc: string;

  constructor(
    private billingService: BillingService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    const params = this.route.snapshot.queryParams;
    if (params.error) {
      this.status = 'error';
      this.error = this.ERRORS[params.error];
      this.errorDesc = params.error_description;
    } else if (!params.state) {
      this.status = 'error';
      this.error = this.ERRORS.no_code;
      this.errorDesc = 'Please try again';
    } else {
      this.billingService
        .verify(params.code, params.state)
        .then(() => {
          this.status = 'complete';
          setTimeout(async () => {
            await this.router.navigate(['/']);
          },         3000);
        })
        .catch((resp) => {
          if (resp.error.error === 'already_verified') {
            this.status = 'already';
          } else if (resp.error.error === 'stripe_account_in_use') {
            this.status = 'error';
            this.error = this.ERRORS.stripe_account_in_use;
          } else {
            this.status = 'error';
            this.error = resp.error.error || 'unknown error';
            this.errorDesc = resp.error.ErrorDescription;
          }
        });
    }
  }

}
