import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TeamService } from '../../../../@core/services/team.service';
import { TrackingService } from '../../../../@core/services/tracking.service';

class Color {
  private minDistance: number = 0.1;
  private correctionStep: number = 0.05;

  red: number;
  green: number;
  blue: number;

  constructor(red: number, green: number, blue: number) {
    this.red = red;
    this.green = green;
    this.blue = blue;
  }

  public ensureDistantValues(): Color {
    let red = this.red;
    const green = this.green;
    let blue = this.blue;

    let farEnough = this.areValuesDistantEnough(red, green, blue);
    let iterations = 0;
    while (!farEnough) {
      const step = this.getCorrectionStep(red, blue);
      red += step;
      blue -= step;
      farEnough = this.areValuesDistantEnough(red, green, blue);

      // tslint:disable-next-line: no-increment-decrement
      if (iterations++ > 5) { break; }
    }

    return new Color(red, green, blue);
  }

  private getCorrectionStep(red: number, blue: number): number {
    return (red > blue) ? this.correctionStep : -this.correctionStep;
  }

  private areValuesDistantEnough(red: number, green: number, blue: number): boolean {

    return Math.abs(red - green) > this.minDistance
      && Math.abs(red - blue) > this.minDistance
      && Math.abs(green - blue) > this.minDistance;
  }

  public toRgbString(): string {
    const red = this.red * 255;
    const green = this.green * 255;
    const blue = this.blue * 255;

    return `rgb(${red},${green},${blue})`;
  }
}
@Component({
  selector: 'app-whatsnew',
  templateUrl: './whatsnew.component.html',
  styleUrls: ['./whatsnew.component.scss'],
})
export class WhatsnewComponent implements OnInit {

  @Output()
  closing = new EventEmitter();

  loading = true;

  items: any[] = [];
  sentFeedbackTitle: string[] = [];

  get activeTeam() {
    return this.teamService.team;
  }

  constructor(
    private teamService: TeamService,
    private tracking: TrackingService,
  ) { }

  async ngOnInit() {
    await this.getWhatsNew();
  }

  close() {
    this.closing.emit();
  }

  async getWhatsNew() {
    this.loading = true;
    const whatsNew = await fetch(`https://www.ensemble.chat/assets/whatsnew.json?t=${Date.now()}`)
      .then((response) => {
        return response.json();
      })
      .catch(() => {
        return;
      })
      .finally(() => {
        this.loading = false;
      });
    if (!whatsNew) {
      return;
    }
    const dateObjects = Object
      .keys(whatsNew)
      .sort((a, b) => { return (new Date(b)).getTime() - (new Date(a)).getTime(); });

    // this.items = dateObjects.map((d, i, arr) => [...d]);

    for (const prop in whatsNew) {
      whatsNew[prop] = whatsNew[prop].map((v: any) => { return { ...v, date: prop }; });
      this.items = this.items.concat(whatsNew[prop]);
    }
  }

  trackEvent(featureTitle: string, value: string) {
    this.tracking.trackEvent("what's new", {
      teamname: this.activeTeam ? this.activeTeam.name : null,
      feature: featureTitle,
      feedback: value,
    });
    this.sentFeedbackTitle.push(featureTitle);
  }

  trackByFn(item) {
    return item.title;
  }

  getTagColor(tag: string) {
    const color = this.getRandomColor(tag.slice(0, 2));
    const rgbColor = color.toRgbString();
    return rgbColor;
  }

  // color helpers
  private getRandomColor(seed: string): Color {
    if (!seed) { throw new Error('Seed cannot be null'); }

    const hashHex = this.hashFnv32a(seed, seed.length + 15);
    const hashForColor = hashHex.substr(hashHex.length - 6, 6);
    const bytes = this.stringToByteArray(hashForColor);

    const color = this.balanceColor(bytes);
    return color.ensureDistantValues();
  }

  private balanceColor(bytes: any[]) {
    const red = Math.max(Math.min(bytes[0] / 255, 0.9), 0.6);
    const green = Math.max(Math.min(bytes[1] / 255, 0.9), 0.3);
    const blue = Math.max(Math.min(bytes[2] / 255, 0.9), 0.4);
    return new Color(red, green, blue);
  }

  private stringToByteArray(hex: string): any[] {
    return [
      parseInt(hex.substr(0, 2), 16) % 255,
      parseInt(hex.substr(2, 4), 16) % 255,
      parseInt(hex.substr(4), 16) % 255,
    ];
  }

  private hashFnv32a(str: string, seed: number): string {
    let i;
    let l;
    let hval = (seed === undefined) ? 0x811c9dc5 : seed;

    for (i = 0, l = str.length; i < l; i++) {
      hval ^= str.charCodeAt(i);
      hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    // Convert to 8 digit hex string
    return (`0000000${(hval >>> 0).toString(16)}`).substr(-8);
  }
}
