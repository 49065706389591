import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../../@core/services/auth.service';

@Component({
  selector: 'app-dev-warning',
  templateUrl: './dev-warning.component.html',
  styleUrls: ['./dev-warning.component.scss'],
})
export class DevWarningComponent implements OnInit {

  isSystem: boolean;
  isProduction: boolean;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.isSystem = this.authService.me.settings['system'];
    this.isProduction = window.location.hostname.indexOf('app.echo.') === -1;
  }

}
