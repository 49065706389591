import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as debug from 'debug';
import { empty, interval, Observable, Subject, Subscription } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { UpgradeComponent } from '../../@shared/layouts/main/upgrade/upgrade.component';
import { Build } from '../../@shared/models/build';

const UPDATE_INTERVAL = 10 * 60 * 1000;
// const UPDATE_INTERVAL = 5 * 1000;

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  hasUpdate$: Observable<boolean>;

  sub: Subscription;

  constructor(
    private http: HttpClient,
    private zone: NgZone,
    private modal: NgbModal,
  ) {
  }

  setup() {
    this.zone.runOutsideAngular(() => {
      this.sub = interval(UPDATE_INTERVAL)
        .pipe(
          takeUntil(this.destroy$),
          switchMap(() => this.http.get<Build>(`/assets/build.json?t=${Date.now()}`).pipe(
            catchError((err) => {
              debug('app:service:update')('update error: %s', err.message);
              return empty();
            }),
          )),
        )
        .subscribe((build: Build) => {
          debug('app:service:update')('check for updates');
          if (build && build.date <= environment.build.date) {
            debug('app:service:update')('no updates found');
            return;
          }
          this.zone.run(() => {
            this.modal.open(UpgradeComponent, {
              backdrop: 'static',
              centered: true,
              size: 'sm',
            });
            this.destroy$.next();
          });
        });
    });
  }
}
