<ng-container *ngIf="!ssoLoading; else ssoLoader">
  <div class="single-box">
    <img src="assets/echofin_dark.png" class="img-fluid mb-4 p-2">
    <div id="error" class="text-center text-danger mb-4" *ngIf="error">
      <strong>Login failed</strong><br>{{ERRORS[error] || ERRORS.default}}
    </div>
    <form (ngSubmit)="login()">
      <div class="form-group">
        <input class="form-control" autocomplete="username" name="identity" [(ngModel)]="identity"
          placeholder="Username or email" [disabled]="loading">
      </div>
      <div class="form-group">
        <input class="form-control" autocomplete="current-password" name="password" [(ngModel)]="password"
          type="password" placeholder="Password" [disabled]="loading">
      </div>
      <div class="text-right">
        <button type="submit" class="btn btn-block btn-success" [disabled]="loading">
          <fa-icon *ngIf="loading" [icon]="['fal','sync-alt']" [fixedWidth]="true" [spin]="true"></fa-icon>
          <fa-icon *ngIf="!loading" [icon]="['fal','sign-in-alt']" [fixedWidth]="true"></fa-icon>
          <span class="ml-2">Login</span>
        </button>
      </div>
    </form>
    <div class="d-flex align-items-center my-2">
      <hr class="flex-fill">
      <span class="text-muted flex-fill text-center">OR</span>
      <hr class="flex-fill">
    </div>
    <div class="text-center text-grey">
      <p><small>You don't remember your password?<br /> <a href="/reset-password">Reset</a> your
          password</small></p>
    </div>
  </div>
  <div class="text-center text-muted p-2">
    <app-copyright></app-copyright>
  </div>
</ng-container>

<iframe #sso [src]="ssoSrc | safe" height="0" frameborder="0" (load)="ssoLoaded()"></iframe>

<ng-template #ssoLoader>
  <div class="root-loader">
    <div class="loader">
      <img src="/assets/echofin_dark.png">
      <span class="spinner"></span>
    </div>
    <span *ngIf="" class="process-message">loading...</span>
  </div>
</ng-template>
