import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { Coupon, CouponReq, Plan, StripePrice } from '../../@shared/models/billing/StripePlan';
import { TeamService } from './team.service';

@Injectable({
  providedIn: 'root',
})
export class BillingService {

  get teamId() {
    return this.teamService.teamId;
  }

  constructor(
    private teamService: TeamService,
    private http: HttpClient,
    private toastr: ToastrService,
  ) { }

  verify(code: string, state: string) {
    return this.http
      .post<{ link: string }>(`${environment.config.endpoints.billing}/billing/accounts/verify`, {
        code,
        state,
      })
      .toPromise();
  }

  getTeamSubscriptions() {
    return this.http
      .get<any>(`${environment.config.endpoints.billing}/billing/subscriptions`, {
        params: {
          team: this.teamId,
        },
      })
      .toPromise();
  }

  getPlans() {
    return this.http
      .get<{
        plans: Plan[],
      }>(`${environment.config.endpoints.billing}/billing/plans`, {
        params: {
          teamId: this.teamId,
        },
      })
      .toPromise();
  }

  getAssignments(roomId) {
    return this.http
      .get<{
        plans: Plan[],
      }>(`${environment.config.endpoints.billing}/billing/assignments`, {
        params: {
          item: roomId,
        },
      })
      .toPromise();
  }

  getRemotePlans() {
    return this.http
      .get<{
        plans: StripePrice[],
      }>(`${environment.config.endpoints.billing}/billing/plans/import`, {
        params: {
          teamId: this.teamId,
        },
      })
      .toPromise();
  }

  addPlan(id) {
    return this.http
      .post(`${environment.config.endpoints.billing}/billing/plans/import`, {
        stripeIds: [id],
      }, {
        params: {
          teamId: this.teamId,
        },
      })
      .toPromise();
  }

  createPlan(plan: any) {
    return this.http
      .post<{ plan: Plan }>(`${environment.config.endpoints.billing}/billing/plans`, plan, {
        params: {
          teamId: this.teamId,
        },
      })
      .toPromise();
  }

  updatePlan(planId: string, plan: any) {
    return this.http
      .put(`${environment.config.endpoints.billing}/billing/plans/${planId}`, plan)
      .toPromise();
  }

  updatePlanCoupons(planId: string, couponIds: string[]) {
    return this.http
      .put(`${environment.config.endpoints.billing}/billing/plans/${planId}/coupons`, {
        couponIds,
      })
      .toPromise();
  }

  archivePlan(id: any) {
    return this.http
      .put(`${environment.config.endpoints.billing}/billing/plans/${id}/archive`, {})
      .toPromise();
  }

  getCoupons() {
    return this.http
      .get<{ coupons: Coupon[] }>(`${environment.config.endpoints.billing}/billing/coupons`, {
        params: {
          teamId: this.teamId,
        },
      })
      .toPromise();
  }

  getCoupon(couponId: string) {
    return this.http
      .get<{ coupon: Coupon }>(`${environment.config.endpoints.billing}/billing/coupons/${couponId}`)
      .toPromise()
      .then((resp) => resp.coupon)
      .catch((resp) => {
        this.toastr.error(resp.error.message);
        return null;
      });
  }

  createCoupon(coupon: CouponReq) {
    return this.http
      .post(`${environment.config.endpoints.billing}/billing/coupons`, coupon)
      .toPromise()
      .catch((resp) => {
        this.toastr.error(resp.error.message);
      });
  }

  updateCoupon(couponId: string, coupon: CouponReq) {
    return this.http
      .put(`${environment.config.endpoints.billing}/billing/coupons/${couponId}`, coupon)
      .toPromise()
      .catch((resp) => {
        this.toastr.error(resp.error.message);
      });
  }

  deleteCoupon(couponId: any) {
    return this.http
      .delete(`${environment.config.endpoints.billing}/billing/coupons/${couponId}`)
      .toPromise()
      .catch((resp) => {
        this.toastr.error(resp.error.message);
      });
  }

  updateAssignments(item, assignments) {
    return this.http
      .post(`${environment.config.endpoints.billing}/billing/assignments`, {
        assignments,
      }, {
        params: { item },
      })
      .toPromise();
  }

  getPayments(endingBefore?: string) {
    const params = {
      teamId: this.teamId,
    };
    if (endingBefore) params['endingBefore'] = endingBefore;
    return this.http
      .get<{
        invoices: any[],
      }>(`${environment.config.endpoints.billing}/billing/payments`, {
        params,
      });
  }
}
