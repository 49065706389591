
declare global {
  interface String {
    getColor(): string;
  }
}

function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i = i + 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

function intToRGB(i) {
  const c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

String.prototype.getColor = function <T>(): string {
  return intToRGB(hashCode(this));
};

export { };
