import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TeamService } from '../services/team.service';

@Injectable({
  providedIn: 'root',
})
export class TeamResolver implements Resolve<void> {

  constructor(
    private teamService: TeamService,
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.teamService.teams.length) {
      return;
    }

    if (!this.teamService.teamId) {
      this.teamService.teamId = this.teamService.teams[0].id;
    }

    await this.teamService.loadTeam(this.teamService.teamId);
  }

}
