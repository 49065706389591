import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';

@Pipe({ name: 'formatDistance', pure: false })
export class FormatDistancePipe implements PipeTransform {
  transform(value) {
    if (!value || Date.now() - value < 60 * 1000) return '';
    return formatDistanceToNow(value, { addSuffix: true });
  }
}
