<div *ngIf="isRead" class="btn-group" [ngbTooltip]="disabledReadDescr" container="body">
  <button class="btn btn-allow active" disabled style="width:47.75px">
    <fa-icon [icon]="['fal','check']"></fa-icon>
  </button>
</div>
<div *ngIf="!isRead && permission" class="btn-group" [ngbTooltip]="tooltipDescr" container="body">
  <button class="btn btn-deny" [disabled]="!enabled" (click)="toggle('DENY')"
    [ngClass]="{active:isDeny}">
    <fa-icon [icon]="['fal','times']"></fa-icon>
  </button>
  <ng-container *ngIf="inheritance && !inheritance.hide">
    <button class="btn btn-outline-secondary btn-inheritance" [disabled]="!enabled" (click)="toggle('INHERIT')"
      [ngClass]="getInheritanceClass()">
      inherit <br> {{inheritance.label || 'DEFAULT'}}
    </button>
  </ng-container>
  <button class="btn btn-allow" [disabled]="!enabled" (click)="toggle('ALLOW')" [ngClass]="{active:isAllow}">
    <fa-icon [icon]="['fal','check']"></fa-icon>
  </button>
</div>
