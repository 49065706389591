import { Injectable } from '@angular/core';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {

  constructor(
    private angulartics2Amplitude: Angulartics2Amplitude,
  ) { }

  public trackEvent(name, params = null) {
    if (environment.config.name !== 'echofin') return;
    this.angulartics2Amplitude.eventTrack(name, params || {});
  }

  public setUserProperties(properties) {
    if (environment.config.name !== 'echofin') return;
    this.angulartics2Amplitude.setUserProperties(properties);
  }
}
