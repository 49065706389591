import { ElementRef, Injectable } from '@angular/core';
import { AuthCheckService, ProfileMeServiceApi } from '@echofin/libraries';
import { MeResp } from '@echofin/libraries/api/profile/models/me-resp';
import { LocalStorageService } from '@efaps/ngx-store';
import { ToastrService } from 'ngx-toastr';
import { concat, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  sso: ElementRef<any>;
  sso$: Subject<any>;
  identity: string;
  me: MeResp;

  constructor(
    private authCheckService: AuthCheckService,
    private localStorageService: LocalStorageService,
    private profileMeServiceApi: ProfileMeServiceApi,
    private toastrService: ToastrService,
  ) {
    this.sso$ = new Subject<any>();
    window.addEventListener('message', e => this.sso$.next(e.data), false);
  }

  async status(): Promise<boolean> {
    return this.authCheckService
      .CheckToken()
      .toPromise()
      .then((resp) => {
        return !!resp.id;
      });
  }

  login(identity: string, password: string): Observable<any> {

    const boardcast = this.broadcastSso({
      password,
      identity,
      type: 'login',
    });

    const sso = this.sso$.pipe(
      take(1),
      map((ev) => {
        switch (ev.type) {
          case 'loggedIn:success': return;
          case 'loggedIn:fail': throw new Error(ev.errorCode);
          default:
            throw new Error(ev.error);
        }
      }),
    );

    return concat(boardcast, sso);
  }

  logout(): Observable<any> {
    const boardcast = this.broadcastSso({
      type: 'logout',
    });
    const sso = this.sso$.pipe(
      take(1),
      map((ev) => {
        switch (ev.type) {
          case 'loggedOut:success':
            this.localStorageService.clear('prefix');
            break;
          default:
            throw new Error(ev.error);
        }
      }),
    );
    return concat(boardcast, sso);
  }

  async loadMe() {
    this.me = await this.profileMeServiceApi
      .GetMeProfile()
      .toPromise()
      .catch(() => {
        this.toastrService.error(
          'Please check your network connection',
          'Load Failed',
        );
        return null;
      });
    if (!this.me) return;
    this.me.settings = this.me.settings || {};
  }

  private broadcastSso(obj: any): Observable<void> {
    return Observable.create((observer) => {
      this.sso
        .nativeElement
        .contentWindow
        .postMessage(obj, '*');
      observer.complete();
    });
  }
}
