import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../@core/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  @ViewChild('sso', { static: true }) sso: ElementRef;

  ERRORS = {
    default: 'An uknown error was occured',
    USER_OR_PASSWORD_WRONG: 'Wrong username or password. Please try again.',
    UKNOWN: 'An uknown error was occured',
  };

  loading: boolean;
  error: string;
  identity: string = '';
  password: string = '';
  team: string;
  ssoLoading = true;
  ssoSrc: string = `${environment.config.endpoints.authentication}/authentication/sso.html`;

  constructor(
    private authService: AuthService,
    private router: Router,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Login');
    this.ssoLoading = true;
  }

  async ssoLoaded() {
    if (!this.ssoLoading) return;
    this.authService.sso = this.sso;
    const isAuth = await this.authService.status();

    this.ssoLoading = false;

    if (isAuth) {
      await this.router.navigate(['/']);
    }
  }

  async login() {
    this.identity = this.identity.trim();
    this.password = this.password.trim();
    if (!this.identity || !this.password) return;
    this.loading = true;
    this.error = null;
    await this.authService
      .login(this.identity, this.password)
      .toPromise()
      .then(() => {
        this.loading = false;
        this.error = null;
      })
      .catch((err) => {
        this.error = err.message;
        this.loading = false;
      });

    if (!this.error) {
      await this.router.navigate(['/']);
    }
  }
}
