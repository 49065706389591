<div class="d-flex align-items-center">
  <button class="picker" (click)="changeImage()" [disabled]="loadingPicker">
    <ng-container *ngIf="!image && !temp">
      <img class="logo" src="/assets/no-image.png">
    </ng-container>
    <ng-container *ngIf="image && !temp">
      <img class="logo" [src]="image">
    </ng-container>
    <ng-container *ngIf="temp">
      <img class="logo" [src]="temp.handle | filestackTransform: transformOptions">
    </ng-container>
    <span class="icon">
      <ng-container *ngIf="loadingPicker">
        <fa-icon icon="cog" [spin]="true"></fa-icon>
      </ng-container>
      <ng-container *ngIf="!loadingPicker">
        <fa-icon icon="cloud-upload"></fa-icon>
      </ng-container>
    </span>
  </button>
  <div *ngIf="!image && !temp" class="alert alert-warning p-2 flex-grow-1 ml-10 mt-3">
    <small>Maximum size of {{this.config.imageMax[0]}}px x {{this.config.imageMax[1]}}px</small>
  </div>
</div>
<button *ngIf="image || temp" class="clearer" (click)="clearImage()"><fa-icon icon="times"></fa-icon> <span>Clear</span></button>
