import { Injectable } from '@angular/core';
import { FilestackService } from '@filestack/angular';
import { PickerFileMetadata, PickerOptions } from 'filestack-js';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { FileUploaded } from '../../@shared/models/filestack/filestack.models';
import { FilestackConfigService } from './filestack-config.service';

const defaults: any = {
  maxSize: 10 * 1024 * 1024,
  maxFiles: 1,
  storeTo: {
    // container: 'devportal-customers-assets',
    // path: 'user-uploads/',
    // region: 'us-east-1',
  },
  uploadInBackground: false,
};

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  defaults: any = {
    maxSize: 10 * 1024 * 1024,
    maxFiles: 1,
    storeTo: {
      // container: 'devportal-customers-assets',
      // path: 'user-uploads/',
      // region: 'us-east-1',
    },
    uploadInBackground: false,
  };
  constructor(
    private toastr: ToastrService,
    private filestackService: FilestackService,
    private filestackConfigService: FilestackConfigService,
  ) { }

  async openPicker(options: Partial<PickerOptions>) {
    const pickerOptions = Object.assign(defaults, options);

    const security = await this.filestackConfigService.getPickerSecurity();

    // filestack service is added twice: in core and shared modules.
    // If we dont add the below the uploaded do not render correctly because the shared service does not initialize
    this.filestackService.init(environment.config.filestackKey, {
      security,
      cname: 'fs.echofin.com',
    });

    return new Promise<FileUploaded[]>(async (resolve, reject) => {

      pickerOptions.onUploadDone = (res) => {
        if (res.filesFailed.length) {
          const failed: PickerFileMetadata[] = res.filesFailed;
          const fNames = failed.reduce((prev, curr) => `${prev} ${curr.filename}`, '');
          this.toastr.error(`${fNames} failed to be uploaded`, 'File Upload Error');
          reject([]);
        }
        if (res.filesUploaded.length) {
          resolve(res.filesUploaded.map(f => new FileUploaded(f)));
        } else {
          this.toastr.error('Something went wrong', 'File Upload Error');
          reject([]);
        }
      };

      await this.filestackService.picker(pickerOptions).open();
    });
  }
}
