import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from '../@core/services/auth.service';
import { UpdateService } from './../@core/services/update.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

  @ViewChild('sso', { static: true }) sso: ElementRef;
  team: string;
  ssoSrc = `${environment.config.endpoints.authentication}/authentication/sso.html`;
  ssoLoading = true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private updater: UpdateService,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Logout');
    this.ssoLoading = true;
  }

  ssoLoaded() {
    if (!this.ssoLoading) return;
    this.ssoLoading = false;
    this.authService.sso = this.sso;
    this.updater.destroy$.next(true);
    this.authService
      .logout()
      .subscribe(() => {
        this.router.navigate(['/login']);
      });
  }
}
